import React from "react";
import Select, { ActionMeta, createFilter } from "react-select";
import SearchImg from "assets/images/search.svg";

interface PropTypes {
  options?: readonly any[];
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
  style?: any;
  backgroundColor?: string;
  placeholder?: string;
  value?: any;
  showClearable?: boolean;
  className?: string;
  classNamePrefix?: string;
  isSearchable?: boolean;
  required?: boolean;
  menuPlacement?: any;
  isDisabled?: boolean;
  defaultValue?: any;
  isMulti?: boolean;
  isSearchIcon?: boolean;
  name?: string;
  validationErrors?: boolean;
}

const DropdownIndicator = (props) => {
  return (
    <div>
      <img src={SearchImg} alt="search" />
    </div>
  );
};

export default function Dropdown({
  options,
  onChange,
  style,
  backgroundColor = "#f4f4f4",
  showClearable = true,
  isSearchable = false,
  isSearchIcon = false,
  ...props
}: PropTypes) {
  const selectorStyle = {
    container: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      borderColor: "transparent !important",
      opacity: isDisabled ? "0.5" : "1",
      ...(style?.container || {}),
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      boxShadow: "unset !important",
      borderColor: "#d4d4d4 !important",
      fontSize: "14px",
      borderRadius: "5px",
      width: "100%",
      cursor: "pointer",
      backgroundColor,
      ...(style?.control || {}),
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#e7f6ff" : "white",
        color: isSelected ? "#000000" : "",
        opacity: isDisabled ? "0.7" : "1",
        cursor: isDisabled ? "none" : "pointer",
        zIndex: 15,
        "&:hover": {
          backgroundColor,
        },
        ...(style?.option || {}),
      };
    },
    input: (styles) => ({ ...styles, color: "#000" }),
  };

  return (
    <Select
      styles={selectorStyle}
      options={options}
      onChange={onChange}
      isClearable={showClearable}
      isSearchable={isSearchable}
      filterOption={createFilter({ ignoreAccents: false })}
      components={isSearchIcon && { DropdownIndicator }}
      {...props}
    />
  );
}
