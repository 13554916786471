import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./Routes";

import RoutesProfile from "routes/RoutesProfile";
import RouterContests from "routes/RoutesContests";
import RoutesMisc from "routes/RoutesMisc";
import RoutesInstitution from "routes/RoutesInstitution";

import Preloader from "components-shared/Preloader/Preloader";
import RouterAuth from "routes/RoutesAuth";
import Login from "components/Login/Login";

import useGlobalEffects from "./useGlobalEffects";
import { useWithAuth } from "./useWithAuth";
import { Roles } from "utils/consts";

const CreateProblems = lazy(() => import("components/CreateProblemSets/CreateProblems"));
const PublishedProblemSets = lazy(
  () => import("components/PublishedProblems/PublishedProblemSets"),
);
const ShowProblemsSets = lazy(() => import("components/ShowProblemSets/ShowProblems"));
const ProblemSets = lazy(() => import("components/ProblemSets/ProblemSets"));
const CollegeStudentsCodingList = lazy(() => import("components/admin-dashboard/CodingProfiles"));

const StudentList = lazy(() => import("components/admin-dashboard/StudentsList/StudentList"));
const Goals = lazy(() => import("components/Goals/Goals"));

const Groups = lazy(() => import("components/admin-dashboard/Groups/Groups"));
const CreateGroup = lazy(() => import("components/admin-dashboard/CreateGroups/CreateGroup"));

const StudentCodingProfile = lazy(() => import("components/StudentCodingProfile"));
const StudentProblems = lazy(() => import("components/StudentProblems/StudentProblems"));

const StudentProfile = lazy(() => import("components/StudentProfile/StudentProfile"));

const Licenses = lazy(() => import("components/admin-dashboard/Licenses/Licenses"));
const Institution = lazy(() => import("components/admin-dashboard/Institution/Institution"));
const StudentProblemSet = lazy(() => import("components/StudentProblemSet/StudentProblemSet"));
const AllProblems = lazy(() => import("components/Problems/AllProblems"));
const PublishedInstitution = lazy(
  () => import("components/PublishedInstitution/PublishedInstitution"),
);
const CommonDesc = lazy(() => import("components/CommonDesc/CommonDesc"));
const Description = lazy(() => import("components/Descriptions/Discription"));
const DashBoard = lazy(() => import("components/admin-dashboard/Dashboard/Dashboard"));
const Contests = lazy(() => import("components/Contests"));
const InstProblemSets = lazy(() => import("components/admin-dashboard/ProblemSets"));
const Assessment = lazy(() => import("components/Assessments/Assessment"));
const InstProblemSetsAssigned = lazy(
  () => import("components/admin-dashboard/ProblemSetsAssigned"),
);
const StudAssessmentMcq = lazy(() => import("components/StudAssessmentMcq/StudAssessmentMcq"));
const StudAssignedAssmnts = lazy(
  () => import("components/StudAssignedAssmnts/StudAssignedAssmnts"),
);
const QuizSets = lazy(() => import("components/admin-dashboard/QuizSets/QuizSets"));
const QuizSetsAssigned = lazy(
  () => import("components/admin-dashboard/QuizSetsAssigned/QuizSetsAssigned"),
);
const AssessmentQuiz = lazy(() => import("components/Assessments/Assessment"));
const StudQuizSetsAssigned = lazy(
  () => import("components/admin-dashboard/StudQuizSetsAssigned/StudQuizSetsAssigned"),
);
const SuppInstAdmins = lazy(() => import("components/SuppInstAdmins/SuppInstAdmins"));
const StudAttendence = lazy(() => import("components/StudAttendence/Attendence"));
const Attendance = lazy(() => import("components/Attendance/Attendence"));

export default function AppRouter(): JSX.Element {
  const { authorize, auth } = useWithAuth();
  useGlobalEffects();

  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        <Route path="/" element={<Navigate to="/contests" />} />
        <Route path={routes.Login} element={<Login />} />
        <Route path="/admin" element={<Login />} />
        <Route
          path={routes.ProbSets}
          element={
            auth([Roles.ContentAdmin, Roles.SuperAdmin])
              ? authorize(<ProblemSets />, [Roles.ContentAdmin, Roles.SuperAdmin, Roles.SuppL0])
              : authorize(<InstProblemSets />, [Roles.InstAdmin, Roles.Trainer, Roles.SuppL0])
          }
        />
        {/* <Route path="/problem-sets" element={authorize(<InstProblemSets />, Roles.InstAdmin)} />   */}
        <Route
          path={routes.Dashboard}
          element={authorize(<DashBoard />, [Roles.InstAdmin, Roles.SuperAdmin, Roles.SuppL0])}
        />
        <Route
          path={`${routes.ProbSets}/:problemsetId`}
          element={
            auth([Roles.ContentAdmin, Roles.SuperAdmin])
              ? authorize(<CreateProblems />, [Roles.ContentAdmin, Roles.SuperAdmin, Roles.SuppL0])
              : authorize(<InstProblemSetsAssigned />, [
                  Roles.InstAdmin,
                  Roles.Trainer,
                  Roles.SuppL0,
                ])
          }
        />
        {/* <Route path="/problem-sets/assigned/:problemSetId" element={<InstProblemSetsAssigned />} /> */}

        <Route
          path={`${routes.PubProbSets}`}
          element={authorize(<PublishedProblemSets />, [Roles.ContentAdmin, Roles.SuperAdmin])}
        />
        <Route
          path={`${routes.PubProbSets}/:assingedProblemsetId`}
          element={authorize(<ShowProblemsSets />, [Roles.ContentAdmin, Roles.SuperAdmin])}
        />

        <Route
          path={routes.StudCodingProfiles}
          element={authorize(<CollegeStudentsCodingList />, [
            Roles.InstAdmin,
            Roles.SuperAdmin,
            Roles.ContentAdmin,
            Roles.Trainer,
            Roles.SuppL0,
          ])}
        />
        <Route
          path={routes.Students}
          element={authorize(<StudentList />, [
            Roles.InstAdmin,
            Roles.SuperAdmin,
            Roles.ContentAdmin,
            Roles.SuppL0,
          ])}
        />
        <Route
          path={routes.Goals}
          element={
            auth([Roles.Student])
              ? authorize(<StudentProblems />, Roles.Student)
              : authorize(<Goals />, [Roles.ContentAdmin, Roles.SuperAdmin, Roles.SuppL0])
          }
        />

        {/* <Route
          path={`${routes.StudProfile}/goals`}
          element={authorize(<StudentProblems />, Roles.Student)}
        /> */}
        <Route
          path={routes.Groups}
          element={authorize(<Groups />, [
            Roles.InstAdmin,
            Roles.SuperAdmin,
            Roles.Trainer,
            Roles.SuppL0,
          ])}
        />

        <Route
          path={`${routes.Groups}/:groupId`}
          element={authorize(<CreateGroup />, [
            Roles.InstAdmin,
            Roles.SuperAdmin,
            Roles.Trainer,
            Roles.SuppL0,
          ])}
        />

        <Route
          path={routes.CodingProfileStudent}
          element={authorize(<StudentCodingProfile />, [
            Roles.InstAdmin,
            Roles.SuperAdmin,
            Roles.ContentAdmin,
            Roles.SuppL0,
            Roles.Student,
          ])}
        />

        <Route
          path={`${routes.StudAccount}`}
          element={authorize(<StudentProfile />, Roles.Student)}
        />
        <Route
          path={`${routes.Attendence}`}
          element={
            auth([Roles.Student])
              ? authorize(<StudAttendence />, Roles.Student)
              : authorize(<Attendance />, [Roles.ContentAdmin, Roles.SuppL0, Roles.SuperAdmin])
          }
        />
        <Route
          path={routes.AllocatedLicenses}
          element={authorize(<Licenses />, [Roles.InstAdmin, Roles.SuperAdmin, Roles.SuppL0])}
        />
        <Route
          path={routes.LicensesList}
          element={authorize(<Institution />, [Roles.SuperAdmin, Roles.SuppL0])}
        />
        <Route
          path={`${routes.StudProblemSets}/:assignedproblemSetId`}
          element={authorize(<StudentProblemSet />, Roles.All)}
        />
        {/* <Route path="profile/*" element={<RoutesProfile />} /> */}
        <Route path={`${routes.Contests}`} element={<Contests />} />
        <Route path="auth/*" element={<RouterAuth />} />
        <Route path={`${routes.Error}/*`} element={<RoutesMisc />} />
        {/* <Route path="college/*" element={<RoutesInstitution />} /> */}
        <Route
          path={routes.Problems}
          element={authorize(<AllProblems />, [Roles.ContentAdmin, Roles.SuperAdmin])}
        />
        <Route
          path={routes.InstPubProbSets}
          element={authorize(<PublishedInstitution />, [Roles.ContentAdmin, Roles.SuperAdmin])}
        />
        <Route
          path={`${routes.InstPubProbSets}/desc`}
          element={authorize(<CommonDesc />, [Roles.ContentAdmin, Roles.SuperAdmin])}
        />
        <Route
          path={`${routes.InstPubProbSets}/desc/:institutionId`}
          element={authorize(<Description />, [Roles.ContentAdmin, Roles.SuperAdmin])}
        />

        {/* assessments */}
        <Route
          path={routes.Assessments}
          element={authorize(<Assessment />, [Roles.ContentAdmin, Roles.SuperAdmin])}
        />
        <Route
          path={`${routes.StudAssessments}`}
          element={authorize(<StudAssessmentMcq />, [Roles.Student])}
        />
        <Route
          path={`${routes.AssessmentQuiz}/:quizId`}
          element={authorize(<StudAssignedAssmnts />, [Roles.Student])}
        />

        <Route
          path={routes.PubAssements}
          element={authorize(<QuizSets />, [Roles.InstAdmin, Roles.Trainer, Roles.SuppL0])}
        />
        <Route
          path={`${routes.ViewPubAssements}/:assessmentId`}
          element={authorize(<QuizSetsAssigned />, [Roles.InstAdmin, Roles.Trainer, Roles.SuppL0])}
        />

        <Route
          path={`${routes.ViewPubAssements}/:assessmentId/:assignedQuizUserId`}
          element={authorize(<StudQuizSetsAssigned />, [
            Roles.InstAdmin,
            Roles.ContentAdmin,
            Roles.SuppL0,
          ])}
        />

        <Route
          path={`${routes.AssessmentQuiz}`}
          element={authorize(<AssessmentQuiz />, [Roles.Student])}
        />

        <Route
          path={`${routes.SuppInstAdmins}`}
          element={authorize(<SuppInstAdmins />, Roles.SuppL0)}
        />
      </Routes>
    </Suspense>
  );
}
